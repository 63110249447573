import React, { useEffect, useState } from "react"
import { Typography } from "@/shared/ui/Typography"

import "../ui/directions.scss"
import AnchorLink from "react-anchor-link-smooth-scroll"

export const DirectionsItems = ({ title, index }) => {
    const [isHover, setHover] = useState(false)

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    let offsets = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
    }
    
    return (
        <AnchorLink
            href={"#section-" + index}
            offset={() => offsets[index]}
            style={{ textDecoration: "none" }}>
            <li
                className={`directions__item directions__hover_${isHover}`}
                key={index}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <Typography
                    className='directions__item-title'
                    variant='h2'
                    color='dark'
                    weight={"light"}>
                    {title}
                </Typography>
                {/* {isHover ? <Icon type='arrow-up-right-large' /> : ''} */}
            </li>
        </AnchorLink>
    )
}
