import React from 'react'
import cls from './styles/typography.module.scss'
import { classNames } from '../lib/classNames'

const variantsMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
}

export const Typography = ({
    children,
    variant,
    color,
    decoration,
    weight,
    style,
    className
}) => {
    const Component = variant ? variantsMapping[variant] : 'p'

    return (
        <Component
            className={classNames(cls.Typography, [cls[color], cls[weight], cls[variant], className])}
            style={{ ...style }}>
            {children}
        </Component>
    )
}
