import React from "react"
import "./home.scss"
import { GreetingBlock } from "@/blocks/GreetingBlock/GreetingBlock"
import { StackBlock } from "@/blocks/StackBlock/StackBlock"
import { WorksBlock } from "@/blocks/WorksBlock/WorksBlock"
import { TeamBlock } from "@/blocks/TeamBlock/TeamBlock"
import { ContactBlock } from "@/blocks/ContactBlock/ContactBlock"

export const HomePage = () => {
    return (
        <main className='home-page'>
            <div className="home-page__container">
                <GreetingBlock />
                <StackBlock />
                <WorksBlock />
                <TeamBlock />
                <ContactBlock />
            </div>
        </main>
    )
}
