import React from 'react'
import { ReactTyped } from 'react-typed'
import { classNames } from '../lib/classNames'
import cls from './uniq-text.module.scss'


export const UniqText = ({className}) => {
    return (
        <h1 className={classNames(cls.uniqText5, [className])}>
            <ReactTyped
                startWhenVisible
                strings={["LET'S TALK"]}
                typeSpeed={200}
                showCursor={false}
            />
        </h1>
    )
}
