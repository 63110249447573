import React from "react"
import { classNames } from "../lib/classNames"
import cls from "./styles/text_area.module.scss"

export const BaseTextArea = ({
    placeholder,
    className,
    value,
    onChange,
    isValid,
}) => {
    return (
        <div className={classNames(cls.container, [className])}>
            <textarea
                className={classNames(cls.baseTextarea, [className], {
                    [cls.invalid]: !isValid,
                })}
                value={value}
                onChange={onChange}
                cols={5}
                placeholder={placeholder + " *"}
            />
            <div
                className={classNames(cls.warning, [], {
                    [cls.hide]: isValid,
                })}>
                Fill this field *
            </div>
        </div>
    )
}
