import { useEffect } from "react"

const useClickOutside = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !e.composedPath().includes(ref.current)) {
                callback()
            }
        }

        const handleEscKeyPress = (e) => {
            if (e.key === "Escape") {
                callback()
            }
        }

        document.addEventListener("click", handleClickOutside)
        document.addEventListener("keydown", handleEscKeyPress)

        return () => {
            document.removeEventListener("click", handleClickOutside)
            document.removeEventListener("keydown", handleEscKeyPress)
        }
    }, [ref, callback])
}

export default useClickOutside
