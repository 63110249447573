import "./App.scss"
import { Header } from "@/widgets/header/Header"
import { Outlet, useLocation } from "react-router-dom"
import { CookieNoticeBanner } from "@/features/CookieNoticeBanner"

export const App = () => {
    const location = useLocation()
    return (
        <div className='App'>
            <Header />
            <Outlet />
            <CookieNoticeBanner />

            <div className={`app-orbit-container path-name-condition__${location.pathname != '/'}`}>
                <div className='app-orbit' />
                <div className='app-orbit second-orbit' />

                <div className='app-anim-circle'>
                    <div className='app-anim-dot' />
                    <div className='app-anim-dot second-dot' />
                </div>
            </div>
        </div>
    )
}

export default App
