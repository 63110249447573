import React from "react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import "@/app/index.scss"
import App from "@/app/App"
import { TermsPage } from "@/pages/terms/TermsPage"
import { HomePage } from "@/pages/home/HomePage"
import { PrivacyPage } from "./pages/privacy/PrivacyPage"
import { CookiePage } from "./pages/cookie/CookiePage"

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: <HomePage />,
            },
            { path: "/terms", element: <TermsPage /> },
            { path: "/cookie", element: <CookiePage /> },
            { path: "/privacy", element: <PrivacyPage /> },
        ],
    },
])

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<RouterProvider router={router} />)
