import React from "react"
import { Footer } from "@/widgets/footer/Footer"
import cls from "./cookie.module.scss"

const data = [
    {
        title: "",
        content: [
            {
                description:
                    "Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology",
                points: [],
            },
        ],
    },
    {
        title: "1. How do we use cookies?",
        content: [
            {
                description:
                    "Our Company collects the following data:",
                points: [
                    "Keeping you signed in",
                    "Understanding how you use our website",
                ],
            },
        ],
    },
    {
        title: "2. What types of cookies do we use?",
        content: [
            {
                description:
                    "There are a number of different types of cookies, however, our website uses:",
                points: [
                    "Functionality – Our Company uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in. A mix of first-party and third-party cookies are used.",
                    "Advertising – Our Company uses these cookies to collect information about your visit to our website, the content you viewed, the links you followed and information about your browser, device, and your IP address. Our Company sometimes shares some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website.",
                ],
            },
        ],
    },
    {
        title: "3. How to manage cookies",
        content: [
            {
                description:
                    "You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.",
                points: [],
            },
        ],
    },
]

export const CookiePage = () => {
    return (
        <div>
            <div className={cls.container}>
                {data.map((item, index) => {
                    return (
                        <div
                            className={cls.item}
                            key={index}>
                            <h3>{item.title}</h3>
                            <div className={cls.subitem}>
                                {item.content.map(
                                    (content, index) => {
                                        return (
                                            <div
                                                className={
                                                    cls.subitem
                                                }>
                                                <p
                                                    className={
                                                        cls.description
                                                    }
                                                    weight='light'
                                                    key={
                                                        index
                                                    }>
                                                    {
                                                        content.description
                                                    }
                                                </p>
                                                {content.points.map(
                                                    (
                                                        point
                                                    ) => {
                                                        return (
                                                            <li
                                                                className={
                                                                    cls.point
                                                                }
                                                                key={
                                                                    index
                                                                }
                                                                weight='light'>
                                                                {
                                                                    point
                                                                }
                                                            </li>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
            <Footer variant={"dark"} />
        </div>
    )
}
