import React, { useState, useEffect } from "react"

function DynamicImage({ imageName, className }) {
    const [imageSrc, setImageSrc] = useState(null)

    useEffect(() => {
        import(`../assets/${imageName}`)
            .then((module) => {
                setImageSrc(module.default)
            })
            .catch((error) => {
                // Handle the error if the image couldn't be loaded
                console.error("Error loading image:", error)
            })
    }, [])

    return (
        <div>
            {imageSrc && (
                <img
                    className={className}
                    src={imageSrc}
                    alt='Dynamic'
                />
            )}
        </div>
    )
}

export default DynamicImage
