import React, { useEffect, useState, useRef } from "react"
import DynamicImage from "@/shared/ui/DynamicImage"
import cls from "./case_item.module.scss"
import { classNames } from "@/shared/lib/classNames"
import useClickOutside from "@/shared/lib/hooks/useClickOutside"

export const CaseItem = ({ item, index, className }) => {
    const [expanded, setExpanded] = useState(false)

    const [windowWidth, setWindowWidth] = useState(
        window.outerWidth
    )

    const caseRef = useRef()

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth)
        })

        return () => {
            window.removeEventListener("resize", () => {
                setWindowWidth(window.innerWidth)
            })
        }
    }, [windowWidth])

    useEffect(() => {
        const slider = document.querySelector(
            `.index${index}`
        )

        let isDown = false
        let startX
        let scrollLeft

        slider.addEventListener("mousedown", (e) => {
            isDown = true
            slider.classList.add("active")
            startX = e.pageX - slider.offsetLeft
            scrollLeft = slider.scrollLeft
        })
        slider.addEventListener("mouseleave", () => {
            isDown = false
            slider.classList.remove("active")
        })
        slider.addEventListener("mouseup", () => {
            isDown = false
            slider.classList.remove("active")
        })
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return
            e.preventDefault()
            const x = e.pageX - slider.offsetLeft
            const walk = (x - startX) * 3 //scroll-fast
            slider.scrollLeft = scrollLeft - walk
        })
    }, [index])

    const clickHandler = () => {
        setExpanded(!expanded)
    }

    useClickOutside(caseRef, () => {
        setExpanded(false)
    })

    return (
        <section
            id={'section-' + index}
            ref={caseRef}
            className={classNames(
                cls.CaseItem,
                [className],
                {
                    [cls.expanded]: expanded,
                }
            )}>
            <div
                onClick={clickHandler}
                className={classNames(cls.headline, [], {
                    [cls.expanded]: expanded,
                })}>
                <h2 className={cls.title}>{item.title}</h2>
                <svg
                    className={classNames(
                        cls.mobileExpandIcon,
                        [],
                        {
                            [cls.expanded]: expanded,
                        }
                    )}
                    width='48'
                    height='48'
                    viewBox='0 0 48 48'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M12 18L24 30L36 18'
                        stroke={
                            expanded ? "black" : "white"
                        }
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                </svg>
            </div>
            <div
                className={classNames(cls.slider, [], {
                    [cls.expanded]: expanded,
                })}>
                <div
                    className={`${cls.sliderContainer} index${index}`}>
                    {item.cases.map((caseItem) => (
                        <div className={cls.sliderItem}>
                            <DynamicImage
                                className={cls.footage}
                                imageName={`${caseItem.footage}.png`}
                            />
                            <h3 className={cls.caseTitle}>
                                {caseItem.title}
                            </h3>
                            <p
                                className={
                                    cls.caseDescription
                                }>
                                {caseItem.description}
                            </p>
                        </div>
                    ))}
                </div>
                <div
                    className={classNames(
                        cls.caseList,
                        [],
                        {
                            [cls.expanded]: expanded,
                        }
                    )}>
                    {item.list}
                </div>
            </div>
        </section>
    )
}
