import React, { useRef, useState } from "react"
import { classNames } from "../lib/classNames"
import cls from "./styles/text_field.module.scss"
import useClickOutside from "../lib/hooks/useClickOutside"

export const TextField = ({ placeholder, value, onChange, isValid, type }) => {
    const [isFocus, setFocus] = useState(false)

    const handleFocus = () => {
        setFocus(true)
    }
    const handleBlur = () => {
        setFocus(false)
    }

    const textFieldRef = useRef()

    useClickOutside(textFieldRef, () => handleBlur())

    return (
        <div
            ref={textFieldRef}
            onClick={() => handleFocus()}
            className={classNames(cls.formControl, [], {
                [cls.focus]: isFocus,
                [cls.invalid]: !isValid,
            })}>
            <label
                className={classNames(cls.inputLabel, [], {
                    [cls.focus]: isFocus || value !== "",
                })}>
                {placeholder + (isFocus || value !== "" ? ":" : " *")}
            </label>
            <input
                type={type}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={value}
                onChange={onChange}
                className={cls.input}
            />
            <div
                className={classNames(cls.warning, [], {
                    [cls.hide]: isValid,
                })}>
                Fill this field *
            </div>
        </div>
    )
}
