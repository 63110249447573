import React from "react"
import { Footer } from "@/widgets/footer/Footer"
import cls from "./privacy.module.scss"

const data = [
    {
        title: "",
        content: [
            {
                description:
                    "Our Company is part of the Our Company Group which includes Our Company International and Our Company Direct. This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.",
                points: [],
            },
        ],
    },
    {
        title: "1. What data do we collect?",
        content: [
            {
                description:
                    "Our Company collects the following data:",
                points: [
                    "Personal identification information (Name, email address, phone number)",
                ],
            },
        ],
    },
    {
        title: "2. How do we collect your data?",
        content: [
            {
                description:
                    "You directly provide Our Company with most of the data we collect. We collect data and process data when you:",
                points: [
                    "Register online or place an order for any of our products or services.",
                    "Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.",
                    "Use or view our website via your browser’s cookies.",
                ],
            },
        ],
    },
    {
        title: "3. How will we use your data?",
        content: [
            {
                description:
                    "Our Company collects your data so that we can:",
                points: [
                    "Process your order and manage your account.",
                    "Email you with special offers on other products and services we think you might like.",
                ],
            },

            {
                description:
                    "When Our Company processes your order, it may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent purchases.",
                points: [],
            },
        ],
    },
    {
        title: "4. Marketing",
        content: [
            {
                description:
                    "Our Company would like to send you information about products and services of ours that we think you might like, as well as those of our partner companies.",
                points: [],
            },

            {
                description:
                    "If you have agreed to receive marketing, you may always opt out at a later date.",
                points: [],
            },
            {
                description:
                    "You have the right at any time to stop Our Company from contacting you for marketing purposes or giving your data to other members of the Our Company Group.",
                points: [],
            },
            {
                description:
                    "If you no longer wish to be contacted for marketing purposes, please click here.",
                points: [],
            },
        ],
    },
    {
        title: "5. What are your data protection rights?",
        content: [
            {
                description:
                    "Our Company would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:",
                points: [],
            },
            {
                description:
                "The right to access – You have the right to request Our Company for copies of your personal data. We may charge you a small fee for this service.",
                points: [],
            },

            {
                description:
                "The right to rectification – You have the right to request that Our Company correct any information you believe is inaccurate. You also have the right to request Our Company to complete the information you believe is incomplete.",
                points: [],
            },
            {
                description:
                "The right to restrict processing – You have the right to request that Our Company restrict the processing of your personal data, under certain conditions.",
                points: [],
            },
            {
                description:
                "The right to object to processing – You have the right to object to Our Company’s processing of your personal data, under certain conditions.",
                points: [],
            },
            {
                description:
                "The right to data portability – You have the right to request that Our Company transfer the data that we have collected to another organization, or directly to you, under certain conditions.",
                points: [],
            },
        ],
    },
]

export const PrivacyPage = () => {
    return (
        <div>
            <div className={cls.container}>
                {data.map((item, index) => {
                    return (
                        <div
                            className={cls.item}
                            key={index}>
                            <h3>{item.title}</h3>
                            <div className={cls.subitem}>
                                {item.content.map(
                                    (content, index) => {
                                        return (
                                            <div
                                                className={
                                                    cls.subitem
                                                }>
                                                <p
                                                    className={
                                                        cls.description
                                                    }
                                                    weight='light'
                                                    key={
                                                        index
                                                    }>
                                                    {
                                                        content.description
                                                    }
                                                </p>
                                                {content.points.map(
                                                    (
                                                        point
                                                    ) => {
                                                        return (
                                                            <li
                                                                className={
                                                                    cls.point
                                                                }
                                                                key={
                                                                    index
                                                                }
                                                                weight='light'>
                                                                {
                                                                    point
                                                                }
                                                            </li>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
            <Footer variant={"dark"} />
        </div>
    )
}
