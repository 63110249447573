import React from 'react'
import { Typography } from '@/shared/ui/Typography'
import './styles/statistics.scss'

export const Statistics = ({className}) => {
    return (
        <ul className={`statistics ${className}`}>
            <li className='statistics__item statistics__item-description'>
                <Typography
                    variant='h4'
                    weight={'standard'}
                    color='dark'>
                    Working with the latest technologies and programming
                    languages, we can qualitatively solve a task of any
                    complexity.
                </Typography>
            </li>

            <li className='statistics__item'>
                <Typography
                    variant='h2'
                    weight={'light'}
                    color='dark'>
                    36M+
                </Typography>
                <Typography
                    variant='h4'
                    weight={'standard'}
                    decoration={'grayscale'}
                    color='dark'>
                    Users of our Products
                </Typography>
            </li>
            <li className='statistics__item'>
                <Typography
                    variant='h2'
                    weight={'light'}
                    color='dark'>
                    $109M+
                </Typography>
                <Typography
                    variant='h4'
                    weight={'standard'}
                    decoration={'grayscale'}
                    color='dark'>
                    Helped to Earn our Clients
                </Typography>
            </li>
        </ul>
    )
}
