import React from "react"
import { DirectionsItems } from "../directions-items/DirectionsItems"
import "./directions.scss"

const Directions = ({ className }) => {
    const data = ["Game Development", "SaaS web products", "Mobile Applications", "Branding and Design"]

    return (
        <ul className={`directions ${className}`}>
            {data.map((item, index) => (
                <DirectionsItems
                    title={item}
                    index={index}
                />
            ))}
        </ul>
    )
}

export default Directions
