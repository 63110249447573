import React from 'react'
import styles from './footer.module.scss'
import { Typography} from '@/shared/ui/Typography'
import { Icon } from '@/shared/ui/Icon'
import { BusinessInfo } from '@/entities/BusinessInfo/BusinessInfo'

export const Footer = ({variant}) => {
  return (
    <footer className={variant === "dark" ? styles.footer_dark : styles.footer}>
        <Typography color={variant !== "dark" ? "dark" : ""} weight="light">© 2023 Axgrid. All rights reserved</Typography>
        <Icon color={variant === "dark" ? "white" : "black"} type="substrat"/>
        <BusinessInfo/>
    </footer>
  )
}
