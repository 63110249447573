import React from "react"
import { Typography } from "@/shared/ui/Typography"
import cls from "./styles/teammate_card.module.scss"

export const TeammateCard = ({ name, role, children }) => {
    return (
        <div className={cls.container}>
            <div className={cls.img}>{children}</div>

            <div className={cls.typography}>
                <Typography
                    className={cls.name}
                    variant='h1'
                    weight='light'>
                    {name}
                </Typography>
                <Typography
                    className={cls.role}
                    weight='light'
                    color='grayscale'>
                    {role.toUpperCase()}
                </Typography>
            </div>
        </div>
    )
}
