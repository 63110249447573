import React, { useEffect, useState } from "react"
import { Logo } from "@/shared/ui/Logo"
import { Navigation } from "@/features/Navigation"
import { Button } from "@/shared/ui/Button"
import { Icon } from "@/shared/ui/Icon"
import { useScrollDirection } from "@/shared/composables/useScrollDirection"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { AgSelect } from "@/shared/ui/select/AgSelect"

import cls from "./header.module.scss"
import { classNames } from "@/shared/lib/classNames"
import { Link, useLocation } from "react-router-dom"

export const Header = () => {
    const scrollDirection = useScrollDirection()
    const location = useLocation()
    const [isHover, setHover] = useState(false)
    const [open, setOpen] = useState(false)
    const [lang, setLang] = useState("en")

    const [windowWidth, setWindowWidth] = useState(
        window.outerWidth
    )

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth)
        })
        if (scrollDirection === "down") {
            setOpen(false)
        }
        return () => {
            window.removeEventListener("resize", () => {
                setWindowWidth(window.innerWidth)
            })
        }
    }, [windowWidth, scrollDirection])

    const handleMouseEnter = () => {
        setHover(true)
    }

    const handleMouseLeave = () => {
        setHover(false)
    }

    const renderLogo = () => {
        if (location.pathname == "/") {
            return <AnchorLink
                offset={() => 500}
                href={"#home_block"}
                style={{ textDecoration: "none" }}>
                <Logo />
            </AnchorLink>
        } else {
            return <Link to='/'>
                <Logo />
            </Link>
        }
    }

    const desktopMenu = () => {
        return (
            <div className={cls.headerContainer}>
                {renderLogo()}
                <Navigation
                    className={cls.desktopNavigation}
                />
                {/* <AgSelect /> */}
                <div
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <AnchorLink
                        offset={250}
                        href={"#contact-block-form"}
                        style={{ textDecoration: "none" }}>
                        <Button
                            className={cls.headerButton}
                            variant='primary'>
                            <div
                                className={
                                    cls.headerButtonText
                                }>
                                Get Innovation
                            </div>
                            <div
                                className={classNames(
                                    cls.headerArrow,
                                    [],
                                    {
                                        [cls.hover]:
                                            isHover,
                                    }
                                )}>
                                <Icon type='arrow-up-right' />
                            </div>
                        </Button>
                    </AnchorLink>
                </div>
            </div>
        )
    }

    const mobileMenu = () => {
        return (
            <>
                <div className={cls.mobileHeaderContainer}>
                    <svg
                        width='36'
                        height='36'
                        viewBox='0 0 36 36'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M22.2866 7.42469L18 0L13.6915 7.46249C13.9439 7.89924 14.2137 8.36798 14.5005 8.86891L18.0003 14.7686L22.2866 7.42469ZM25.6794 13.3011L22.9114 17.8736L21.8389 19.7367L27.258 29.0519L28.5017 31.1769H36L25.6794 13.3011ZM21.6934 31.1769L17.9439 24.6272L14.2394 31.1769H21.6934ZM7.45549 31.1769L11.7909 23.7733L14.1618 19.7367L10.3122 13.3156L0 31.1769H7.45549Z'
                            fill='white'
                        />
                    </svg>

                    <div
                        onClick={() => setOpen(!open)}
                        className={cls.mobileMenuButton}>
                        {open ? (
                            <svg
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='transparent'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M4 4L20 20M20 4L4 20'
                                    stroke='white'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                stroke-width='1.5'
                                stroke='currentColor'
                                class='size-6'>
                                <path
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                    d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
                                />
                            </svg>
                        )}
                    </div>
                </div>
                <div
                    style={{
                        height: `${
                            window.innerHeight -
                            78 -
                            56 -
                            42
                        }px`,
                    }}
                    className={classNames(
                        cls.headerMenu,
                        [],
                        {
                            [cls.open]: open,
                        }
                    )}>
                    <Navigation
                        className={cls.mobileNavigation}
                    />
                    {/* <div className={cls.langSelector}>
                        {["en", "de", "ru"].map((langItem) => (
                            <div
                                onClick={() => setLang(langItem)}
                                className={classNames(cls.langItem, [], {
                                    [cls.active]: lang === langItem,
                                })}>
                                {langItem}
                            </div>
                        ))}
                    </div> */}
                    <AnchorLink
                        offset={250}
                        href={"#contact-block-form"}
                        onClick={() => setOpen(false)}
                        style={{ textDecoration: "none" }}>
                        <Button
                            className={cls.headerButton}
                            variant='primary'>
                            <div
                                className={
                                    cls.headerButtonText
                                }>
                                Get Innovation
                            </div>
                            <div
                                className={classNames(
                                    cls.headerArrow,
                                    [],
                                    {
                                        [cls.hover]:
                                            isHover,
                                    }
                                )}>
                                <Icon type='arrow-up-right' />
                            </div>
                        </Button>
                    </AnchorLink>
                </div>
            </>
        )
    }

    const headerRender = (windowWidth) => {
        if (windowWidth < 768) {
            return mobileMenu()
        } else {
            return desktopMenu()
        }
    }

    return (
        <header
            className={classNames(cls.header, [
                cls[`scroll_${scrollDirection}`],
            ])}>
            {headerRender(windowWidth)}
        </header>
    )
}
