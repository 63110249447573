import React from "react"
import { classNames } from "../lib/classNames"

import cls from "./styles/button.module.scss"

export const Button = ({
    children,
    variant,
    size,
    className,
    onClick,
    disabled,
}) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={classNames(cls.baseButton, [cls[variant], cls[size], className], {
                [cls.disabled]: disabled,
            })}>
            {children}
        </button>
    )
}
