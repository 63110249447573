import React, { useEffect, useState } from "react"
import { classNames } from "../../lib/classNames"
import { CSSTransition } from "react-transition-group"
import cls from "./Circle.module.scss"

export const CircleTooltip = ({ children, text }) => {
    const [showTooltip, setShowToolTip] = useState(false)

    const onMouseEnterHandler = () => {
        setShowToolTip(true)
    }

    const onMouseLeaveHandler = () => {
        setShowToolTip(false)
    }

    useEffect(() => {
        const cursor = document.getElementById("tooltip")
        const content = document.getElementById("content")

        const updateCoordinates = (e) => {
            if (cursor) {
                cursor.style.left = e.clientX - 60 + "px"
                cursor.style.top = e.clientY + 10 + "px"
            }
        }
        
        window.addEventListener("mousemove", updateCoordinates)

        return () => window.removeEventListener("mousemove", updateCoordinates)
    }, [onMouseEnterHandler, onMouseLeaveHandler])

    
    return (
        <div
            id='content'
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            className={cls.TooltipContainer}
            style={{ cursor: "pointer" }}>
            {children}
            <CSSTransition
                in={showTooltip}
                unmountOnExit>
                <div
                    id='tooltip'
                    className={classNames(cls.tooltip, [], {
                        [cls.show]: showTooltip,
                    })}>
                    {text}
                </div>
            </CSSTransition>
        </div>
    )
}
