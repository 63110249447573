import React from "react"
import "./styles/icon.scss"

export const Icon = ({ type, color, className, onClick, stroke }) => {
    return (
        <div className='base-icon'>
            {type === "arrow-up-right" ? (
                <svg
                    width='24'
                    height='25'
                    viewBox='0 0 24 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M7 17.5L17 7.5M17 7.5H8M17 7.5V16.5'
                        stroke='#131A29'
                        strokeWidth='2'
                        strokewinecap='round'
                        strokewinejoin='round'
                    />
                </svg>
            ) : (
                ""
            )}
            {type === "star-mark" ? (
                <svg
                    width='24'
                    height='25'
                    viewBox='0 0 24 25'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M20.5312 9.29905L19.0347 6.70095L13.5 9.90191V3.5H10.5V9.90191L4.96533 6.70095L3.46823 9.29905L9.00431 12.5L3.46865 15.701L4.96528 18.299L10.5 15.0981V21.5H13.5V15.0981L19.0346 18.299L20.5318 15.701L14.9957 12.5L20.5312 9.29905Z'
                        fill='white'
                    />
                </svg>
            ) : (
                ""
            )}
            {type === "arrow-up-right-large" ? (
                <svg
                    width='42'
                    height='42'
                    viewBox='0 0 42 42'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M16.3333 39.6668L39.6667 16.3335M39.6667 16.3335H18.6667M39.6667 16.3335V37.3335'
                        stroke='black'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                </svg>
            ) : (
                ""
            )}
            {type === "arrow-up-right-xlarge" ? (
                <svg
                    width='72'
                    height='72'
                    viewBox='0 0 72 72'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M21 51L51 21M51 21H24M51 21V48'
                        stroke='black'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </svg>
            ) : (
                ""
            )}
            {type === "substrat" ? (
                <svg
                    width='37'
                    height='32'
                    viewBox='0 0 37 32'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M22.8327 7.54742L18.4752 0L14.1527 7.48684C14.4045 7.93149 14.6731 8.40771 14.9581 8.9157L18.5329 15.0638L22.8327 7.54742ZM26.3323 13.6088L23.5491 18.2997L22.4536 20.2412L27.9887 29.9488L29.1654 32H36.9504L26.3323 13.6088ZM22.2133 32L18.4752 25.3377L14.782 32H22.2133ZM7.85605 32L12.1905 24.4478L14.6121 20.2412L10.6661 13.5257L4.52995e-06 32H7.85605Z'
                        fill={color}
                    />
                </svg>
            ) : (
                ""
            )}
            {type === "links" ? (
                <svg
                    width='207'
                    height='15'
                    viewBox='0 0 207 15'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M15.7539 8.625H13.7031V14.75H10.9688V8.625H8.72656V6.10938H10.9688V4.16797C10.9688 1.98047 12.2812 0.75 14.2773 0.75C15.2344 0.75 16.2461 0.941406 16.2461 0.941406V3.10156H15.125C14.0312 3.10156 13.7031 3.75781 13.7031 4.46875V6.10938H16.1367L15.7539 8.625Z'
                        fill={color}
                    />
                    <path
                        d='M74.5508 4.90625C74.5508 5.04297 74.5508 5.15234 74.5508 5.28906C74.5508 9.08984 71.6797 13.4375 66.4023 13.4375C64.7617 13.4375 63.2578 12.9727 62 12.1523C62.2188 12.1797 62.4375 12.207 62.6836 12.207C64.0234 12.207 65.2539 11.7422 66.2383 10.9766C64.9805 10.9492 63.9141 10.1289 63.5586 8.98047C63.75 9.00781 63.9141 9.03516 64.1055 9.03516C64.3516 9.03516 64.625 8.98047 64.8438 8.92578C63.5312 8.65234 62.5469 7.50391 62.5469 6.10938V6.08203C62.9297 6.30078 63.3945 6.41016 63.8594 6.4375C63.0664 5.91797 62.5742 5.04297 62.5742 4.05859C62.5742 3.51172 62.7109 3.01953 62.957 2.60938C64.3789 4.33203 66.5117 5.48047 68.8906 5.61719C68.8359 5.39844 68.8086 5.17969 68.8086 4.96094C68.8086 3.375 70.0938 2.08984 71.6797 2.08984C72.5 2.08984 73.2383 2.41797 73.7852 2.99219C74.4141 2.85547 75.043 2.60938 75.5898 2.28125C75.3711 2.96484 74.9336 3.51172 74.332 3.86719C74.9062 3.8125 75.4805 3.64844 75.9727 3.42969C75.5898 4.00391 75.0977 4.49609 74.5508 4.90625Z'
                        fill={color}
                    />
                    <path
                        d='M136.012 4.16797C136.34 5.31641 136.34 7.77734 136.34 7.77734C136.34 7.77734 136.34 10.2109 136.012 11.3867C135.848 12.043 135.328 12.5352 134.699 12.6992C133.523 13 128.875 13 128.875 13C128.875 13 124.199 13 123.023 12.6992C122.395 12.5352 121.875 12.043 121.711 11.3867C121.383 10.2109 121.383 7.77734 121.383 7.77734C121.383 7.77734 121.383 5.31641 121.711 4.16797C121.875 3.51172 122.395 2.99219 123.023 2.82812C124.199 2.5 128.875 2.5 128.875 2.5C128.875 2.5 133.523 2.5 134.699 2.82812C135.328 2.99219 135.848 3.51172 136.012 4.16797ZM127.344 9.99219L131.227 7.77734L127.344 5.5625V9.99219Z'
                        fill={color}
                    />
                    <path
                        d='M186.125 4.60547C187.848 4.60547 189.27 6.02734 189.27 7.75C189.27 9.5 187.848 10.8945 186.125 10.8945C184.375 10.8945 182.98 9.5 182.98 7.75C182.98 6.02734 184.375 4.60547 186.125 4.60547ZM186.125 9.80078C187.246 9.80078 188.148 8.89844 188.148 7.75C188.148 6.62891 187.246 5.72656 186.125 5.72656C184.977 5.72656 184.074 6.62891 184.074 7.75C184.074 8.89844 185.004 9.80078 186.125 9.80078ZM190.117 4.49609C190.117 4.90625 189.789 5.23438 189.379 5.23438C188.969 5.23438 188.641 4.90625 188.641 4.49609C188.641 4.08594 188.969 3.75781 189.379 3.75781C189.789 3.75781 190.117 4.08594 190.117 4.49609ZM192.195 5.23438C192.25 6.24609 192.25 9.28125 192.195 10.293C192.141 11.2773 191.922 12.125 191.211 12.8633C190.5 13.5742 189.625 13.793 188.641 13.8477C187.629 13.9023 184.594 13.9023 183.582 13.8477C182.598 13.793 181.75 13.5742 181.012 12.8633C180.301 12.125 180.082 11.2773 180.027 10.293C179.973 9.28125 179.973 6.24609 180.027 5.23438C180.082 4.25 180.301 3.375 181.012 2.66406C181.75 1.95312 182.598 1.73438 183.582 1.67969C184.594 1.625 187.629 1.625 188.641 1.67969C189.625 1.73438 190.5 1.95312 191.211 2.66406C191.922 3.375 192.141 4.25 192.195 5.23438ZM190.883 11.3594C191.211 10.5664 191.129 8.65234 191.129 7.75C191.129 6.875 191.211 4.96094 190.883 4.14062C190.664 3.62109 190.254 3.18359 189.734 2.99219C188.914 2.66406 187 2.74609 186.125 2.74609C185.223 2.74609 183.309 2.66406 182.516 2.99219C181.969 3.21094 181.559 3.62109 181.34 4.14062C181.012 4.96094 181.094 6.875 181.094 7.75C181.094 8.65234 181.012 10.5664 181.34 11.3594C181.559 11.9062 181.969 12.3164 182.516 12.5352C183.309 12.8633 185.223 12.7812 186.125 12.7812C187 12.7812 188.914 12.8633 189.734 12.5352C190.254 12.3164 190.691 11.9062 190.883 11.3594Z'
                        fill={color}
                    />
                </svg>
            ) : (
                ""
            )}
            {type === "select-arrow" ? (
                <svg
                    className={className}
                    onClick={onClick}
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M16.0686 9H7.93137C7.32555 9 7.02265 9 6.88238 9.1198C6.76068 9.22374 6.69609 9.37967 6.70865 9.53923C6.72312 9.72312 6.93731 9.93731 7.36569 10.3657L11.4343 14.4343C11.6323 14.6323 11.7313 14.7313 11.8455 14.7684C11.9459 14.8011 12.0541 14.8011 12.1545 14.7684C12.2687 14.7313 12.3677 14.6323 12.5657 14.4343L16.6343 10.3657L16.6343 10.3657C17.0627 9.93731 17.2769 9.72312 17.2914 9.53923C17.3039 9.37967 17.2393 9.22374 17.1176 9.1198C16.9774 9 16.6744 9 16.0686 9Z'
                        stroke={stroke}
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                </svg>
            ) : (
                ""
            )}
        </div>
    )
}
