import React from "react"
import { Typography } from "@/shared/ui/Typography"
import { Icon } from "@/shared/ui/Icon"
import { useState } from "react"
import "./styles/blog_post.scss"

export const BlogPost = ({title, description}) => {
    const [isHover, setIsHover] = useState(false)

    const handleMouseEnter = () => {
        setIsHover(true)
    }
    const handleMouseLeave = () => {
        setIsHover(false)
    }
    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className='blog-post'>
            <div className='blog-post__title'>
                <Typography
                    variant='h3'
                    className={"blog-post__title"}
                    color={isHover ? "dark" : ""}
                    weight='medium'>
                    {title}
                </Typography>
            </div>
            <div className='blog-post__divider'></div>
            <Typography
                className={"blog-post__text"}
                color={isHover ? "dark" : ""}
                weight='light'>
                {description}
            </Typography>
        </div>
    )
}
