import React from "react"
import { Typography } from "@/shared/ui/Typography"
import { Button } from "@/shared/ui/Button"
import { Icon } from "@/shared/ui/Icon"
import cls from "./greeting_block.module.scss"
import AnchorLink from "react-anchor-link-smooth-scroll"

export const GreetingBlock = () => {
    return (
        <div
            id='home_block'
            className={cls.greetingBlock}>
            <div className={cls.greetingBlockTypography}>
                <Typography
                    className={cls.greetingBlockTypography}
                    variant={"h2"}
                    weight={500}>
                    <span>Crafting</span> <br />{" "}
                    <span className={cls.greetingBlockSpan}>Digital</span>{" "}
                    <br /> <span className={cls.greetingBlockSpan2}>Solutions</span>
                </Typography>
            </div>
            <AnchorLink
                offset='200'
                href={"#contact-block-form"}
                style={{ textDecoration: "none" }}>
                <Button
                    className={cls.greetingBlockButton}
                    variant={"secondary"}>
                    <Icon type={"star-mark"} />
                    Available for Work
                </Button>
            </AnchorLink>
        </div>
    )
}
