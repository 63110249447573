import React, { useEffect, useState } from "react"
import { Directions } from "@/entities/directions/ui"
import { Stack } from "@/entities/stack/ui/Stack"
import { Statistics } from "@/entities/Statistics"
import Wave from "react-wavify"
import cls from "./stack_block.module.scss"
import { ReactTyped } from "react-typed"

export const StackBlock = () => {
    const [windowWidth, setWindowWidth] = useState(
        window.outerWidth
    )

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.outerWidth)
        })

        return () => {
            window.removeEventListener("resize", () => {
                setWindowWidth(window.outerWidth)
            })
        }
    }, [windowWidth])

    return (
        <div
            id='stack_block'
            className={cls.stackBlock}>
            <div className={cls.waveUp}>
                <Wave
                    fill='url(#gradient)'
                    filter='url(#filter-1)'
                    amplitude={20}
                    speed={0.1}
                    points={2}>
                    <defs>
                        <linearGradient
                            id='gradient'
                            gradientTransform='rotate(90)'>
                            <stop
                                offset='10%'
                                stopColor='#CAFE14'
                            />
                            <stop
                                offset='50%'
                                stopColor='white'
                            />
                            <stop
                                offset='100%'
                                stopColor='white'
                            />
                        </linearGradient>
                        <filter id='filter-1'>
                            <feGaussianBlur
                                in='FillPaint'
                                stdDeviation='0, 5'
                            />
                        </filter>
                    </defs>
                </Wave>
            </div>
            <div className={cls.explanation}>
                <svg
                    className={cls.star}
                    width='56'
                    height='56'
                    viewBox='0 0 56 56'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M29.7532 -3.46877e-07L29.0439 25.3654L46.4626 6.92185L49.064 9.51673L30.5815 26.8986L56 26.1909L56 29.8091L30.5815 29.1009L49.064 46.4833L46.4626 49.0786L29.0444 30.6351L29.7532 56L26.1278 56L26.9556 30.6351L9.49702 49.0787L6.93549 46.4833L25.4185 29.1009L-1.14484e-06 29.8091L-1.303e-06 26.1909L25.4185 26.8986L6.936 9.51723L9.49702 6.92135L26.9556 25.3654L26.2458 0.000507161L29.7532 -3.46877e-07Z'
                        fill='black'
                    />
                </svg>
                <div className={cls.explanationText}>
                    We specialize in web services, mobile
                    applications, and business solutions
                </div>
            </div>
            <div className={cls.headline}>
                <span>
                    <ReactTyped
                        startDelay={1000}
                        startWhenVisible
                        strings={["FROM IDEA TO"]}
                        typeSpeed={200}
                        showCursor={false}
                    />
                </span>
                <br />
                <span className={cls.secondSpan}>
                    <ReactTyped
                        startDelay={3000}
                        startWhenVisible
                        strings={["GREAT IMPACT"]}
                        typeSpeed={200}
                        showCursor={false}
                    />
                </span>
            </div>

            <div className={cls.technologies}>
                <Directions className={cls.directions} />
                <Statistics className={cls.statistics} />
            </div>

            <Stack className={cls.stack} />

            <div className={cls.waveDown}>
                <Wave
                    fill='url(#gradient-2)'
                    filter='url(#filter-2)'
                    amplitude={20}
                    speed={0.1}
                    points={2}>
                    <defs>
                        <linearGradient
                            id='gradient-2'
                            gradientTransform='rotate(90)'>
                            <stop
                                offset='10%'
                                stopColor='#CAFE14'
                            />
                            <stop
                                offset='50%'
                                stopColor='white'
                            />
                            <stop
                                offset='100%'
                                stopColor='white'
                            />
                        </linearGradient>
                        <filter id='filter-2'>
                            <feGaussianBlur
                                in='FillPaint'
                                stdDeviation='0, 5'
                            />
                        </filter>
                    </defs>
                </Wave>
            </div>
        </div>
    )
}
