import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

import cls from "./styles/navigation.module.scss"
import { classNames } from "@/shared/lib/classNames"
import { Link, useLocation } from "react-router-dom"

export const Navigation = ({ className }) => {
    const location = useLocation()
    const data = [
        {
            name: "Home",
            link: "#home_block",
            scroll_offset: 500,
        },
        {
            name: "Services",
            link: "#stack_block",
            scroll_offset: 100,
        },
        {
            name: "Works",
            link: "#works_block",
            scroll_offset: 100,
        },
        {
            name: "About",
            link: "#team_block",
            scroll_offset: 100,
        },
        {
            name: "Contacts",
            link: "#contact_block",
            scroll_offset: 0,
        },
    ]

    const render = (item) => {
        if (location.pathname == "/") {
            return (
                <AnchorLink
                    className={cls.navigationItem}
                    offset={() => item.scroll_offset}
                    href={item.link}>
                    {item.name}
                </AnchorLink>
            )
        } else {
            return (
                <Link className={cls.navigationItem} to='/'>
                    {item.name}
                </Link>
            )
        }
    }
    return (
        <ul
            className={classNames(cls.nabigationList, [
                className,
            ])}>
            {data.map((item, index) => {
                return (
                    <li
                        className={cls.navigationItem}
                        key={index}>
                        {render(item)}
                    </li>
                )
            })}
        </ul>
    )
}
