import React from "react"
import Marquee from "react-fast-marquee"
import { classNames } from "../lib/classNames"
import cls from './uniq-text.module.scss'


export const UniqMarqueeText = ({className}) => {
    return (
        <div className={classNames(cls.uniqMarqueeText, [className])}>
            <Marquee
                className={classNames(cls.uniqMarqueeText, [className])}
                speed={200}>
                <span>Over 20 successful projects.</span>
            </Marquee>
        </div>
    )
}
