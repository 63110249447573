import React from "react"

import "./stack.scss"

import { StackItem } from "../stack-item/StackItem"
import { CircleTooltip } from "@/shared/ui/CircleTooltip/CircleTooltip"
import Marquee from "react-fast-marquee"

const data = [
    {
        title: "GO LANG",
        icon: "go-icon",
    },
    {
        title: "REACT",
        icon: "react-icon",
    },
    {
        title: "JAVASCRIPT",
        icon: "js-icon",
    },
    {
        title: "TYPESCRIPT",
        icon: "ts-icon",
    },
    {
        title: "UNITY",
        icon: "unity-icon",
    },
    {
        title: "DESIGN",
        icon: "figma-icon",
    },
]

export const Stack = ({ className }) => {
    return (
        <CircleTooltip text='Our Stack'>
            <ul className={`stack__list ${className}`}>
                {window.outerWidth > 768 ? (
                    data.map((item, index) => (
                        <StackItem
                            item={item}
                            index={index}
                            key={index}
                        />
                    ))
                ) : (
                    <Marquee>
                        {data.map((item, index) => (
                            <StackItem
                                item={item}
                                index={index}
                                key={index}
                            />
                        ))}
                    </Marquee>
                )}
            </ul>
        </CircleTooltip>
    )
}
