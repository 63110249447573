import React from "react"
import { Footer } from "@/widgets/footer/Footer"
import cls from "./terms.module.scss"

const data = [
    {
        title: "1. POEditor Terms of Service",
        content: [
            `1.1. POEditor is an online service ("Service") which can be used on various stationary and/or mobile devices. POEditor is operated by CODE WHALE INC., 1004 Sandstone Drive, Jeffersonville, IN 47130, United States ("CODE WHALE"). This document sets forth certain terms and conditions ("Terms") of the legal contract between you ("User") and CODE WHALE with respect to User's use of the CODE WHALE Service, whether as an individual user or representing an organization.`,
        ],
    },
    {
        title: "2. Accepting the terms",
        content: [
            `2.1. In order to use the Service, you must agree to the Terms.`,
            `2.2. You can accept the terms by clicking to accept or to agree to the Terms, where this option is made available to you by CODE WHALE in the user interface for the Service. If no such option is available, you agree to the Terms by actually using the Service. In this case, you understand and agree that CODE WHALE will treat your use of the Service as acceptance of the Terms from that point onwards.`,
            `2.3. To use the Service you are required to be older than 16. You hereby certify that you are at least 16 years old.`,
        ],
    },
    {
        title: "3. POEditor Terms of Service",
        content: [
            `1.1. POEditor is an online service ("Service") which can be used on various stationary and/or mobile devices. POEditor is operated by CODE WHALE INC., 1004 Sandstone Drive, Jeffersonville, IN 47130, United States ("CODE WHALE"). This document sets forth certain terms and conditions ("Terms") of the legal contract between you ("User") and CODE WHALE with respect to User's use of the CODE WHALE Service, whether as an individual user or representing an organization.`,
        ],
    },
    {
        title: "4. Accepting the terms",
        content: [
            `2.1. In order to use the Service, you must agree to the Terms.`,
            `2.2. You can accept the terms by clicking to accept or to agree to the Terms, where this option is made available to you by CODE WHALE in the user interface for the Service. If no such option is available, you agree to the Terms by actually using the Service. In this case, you understand and agree that CODE WHALE will treat your use of the Service as acceptance of the Terms from that point onwards.`,
            `2.3. To use the Service you are required to be older than 16. You hereby certify that you are at least 16 years old.`,
        ],
    },
]

export const TermsPage = () => {
    return (
        <div>
            <div className={cls.container}>
                <p className={cls.date}>
                    {"Updated: March 23, 2020".toUpperCase()}
                </p>
                {data.map((item, index) => {
                    return (
                        <div
                            className={cls.item}
                            key={index}>
                            <h3>{item.title}</h3>
                            <div className={cls.subitem}>
                                {item.content.map((content, index) => {
                                    return (
                                        <p
                                            weight='light'
                                            key={index}>
                                            {content}
                                        </p>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
            <Footer variant={"dark"} />
        </div>
    )
}
