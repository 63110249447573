import React, { useEffect, useState } from "react"
import { CaseItem } from "@/entities/CaseItem/CaseItem"
import cls from "./dropdown_list.module.scss"

export const DropdownList = () => {
    const data = [
        {
            title: "Game Development",
            list: 'pilot 2d, tank coins and more',
            cases: [
                {
                    title: "Mobile Games",
                    description: "Engaging and addictive mobile games designed for wide appeal.",
                    footage: "mobile_games",
                },
                {
                    title: "Gambling Web & Desktop",
                    description: "High-quality, thrilling gambling experiences for web and desktop platforms.",
                    footage: "gambling",
                },
                {
                    title: "Indie 2D RPG games",
                    description: "Unique and immersive RPG and card games for a dedicated player base.",
                    footage: "indie_2d",
                },
            ],
        },
        {
            title: "SaaS web products",
            list: 'Graphix, Chartix and more',
            cases: [
                {
                    title: "Business Optimization Solutions",
                    description:
                        "Streamline your operations with our tools, encompassing CRM, task management, custom version control, and production process control.",
                    footage: "bus_solutions",
                },
                {
                    title: "Advertising Business & Screen Administration:",
                    description:
                        "Manage your advertising network effortlessly with our admin panel, screen software, and mobile app for updates",
                    footage: "bus_products",
                },
                {
                    title: "IT Business Products:",
                    description:
                        "Optimize your data management and communication with our advanced solutions for log visualization, metrics dashboards, and neural network-powered translation",
                    footage: "screen_administration",
                },
            ],
        },
        {
            title: "Mobile applications",
            list: 'to do list, bde and more',
            cases: [
                {
                    title: "ToDo Taskbook",
                    description: "A user-friendly mobile app for managing your daily tasks",
                    footage: "todo_app",
                },
                {
                    title: "BDE Mobile App",
                    description: "Monitor employee performance and production processes on the go.",
                    footage: "bde_mobile",
                },
                {
                    title: "Event Management",
                    description:
                        "Helps users plan and manage events, including booking venues, organizing schedules, and coordinating with attendees.",
                    footage: "event_management",
                },
            ],
        },
        {
            title: "Branding and design",
            list: 'Desirmort, Vigiland and more',
            cases: [
                {
                    title: "Gambling Platform Packaging",
                    description: "A user-friendly mobile app for managing your daily tasks",
                    footage: "gambling_packaging",
                },
                {
                    title: "Arcade Game Platform Packaging",
                    description: "Engaging and eye-catching branding for your arcade game platform",
                    footage: "arcade_packaging",
                },
                {
                    title: "Business Packaging",
                    description: "Professional and polished design for your company's brand identity",
                    footage: "business_packaging",
                },
            ],
        },
    ]

    return (
        <ul
            id='#dropdown-list'
            className={cls.dropdownList}>
            {data.map((item, index) => (
                <li className={cls.item}>
                    <CaseItem
                        item={item}
                        index={index}
                        key={item.title}
                    />
                    <div
                        className={cls.border}
                        key={index}></div>
                </li>
            ))}
        </ul>
    )
}
