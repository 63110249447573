import { React } from "react"
import { StackIcons } from "@/entities/stack/stack-icons/StackIcons"
import "../ui/stack.scss"
import { Typography } from "@/shared/ui/Typography"

export const StackItem = ({ item, index }) => {
    return (
        <>
            <li
                className='stack__item'
                id={"element" + index}
                key={index}>
                <StackIcons type={item.icon} />
                {/* <Typography
                    color='gray'
                    decoration='grayscale'
                    weight={"light"}>
                    {item.title}
                </Typography> */}
            </li>
        </>
    )
}
