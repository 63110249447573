import { React, useEffect, useState } from "react"

import cls from "./styles/cookie_notice_banner.module.scss"
import { classNames } from "@/shared/lib/classNames"
import { useCookies } from "react-cookie"
import { Link } from "react-router-dom"

export const CookieNoticeBanner = () => {
    const [is_open, setOpen] = useState(true)

    const [cookies, setCookie, removeCookie] = useCookies([
        "cookies",
    ])

    useEffect(() => {
        if (cookies.isVisited) {
            document.getElementById(
                "cookieNotice"
            ).style.display = "none"
            setOpen(false)
        }
    }, [cookies])

    const onDecline = () => {
        setOpen(false)
        removeCookie("cookies", { path: "/" })
    }

    const onAccept = () => {
        setOpen(false)
        setCookie("isVisited", true)
    }

    return (
        <div
            id='cookieNotice'
            className={classNames(
                cls.cookieNoticeBanner,
                [],
                {
                    [cls.close]: !is_open,
                }
            )}>
            <div className={cls.text}>
                This website uses cookies to ensure you get
                the best experience. By continue to use..
                <Link
                    to='/cookie'
                    style={{ color: "white" }}>
                    More Information
                </Link>
            </div>
            <div className={cls.buttons}>
                <button
                    onClick={() => onDecline()}
                    className={cls.decline}>
                    Decline
                </button>
                <button
                    onClick={() => onDecline()}
                    className={cls.mobileDecline}>
                    <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M4 4L20 20M20 4L4 20'
                            stroke='white'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                        />
                    </svg>
                </button>
                <button
                    onClick={() => onAccept()}
                    className={cls.accept}>
                    Accept all cookies
                </button>
            </div>
        </div>
    )
}
