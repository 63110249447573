import React, { useEffect, useState } from "react"
import { Typography } from "@/shared/ui/Typography"
import { TeammateCard } from "@/entities/TeammateCard"
import { BlogPost } from "@/entities/BlogPost.js"
import Marquee from "react-fast-marquee"
import { ReactTyped } from "react-typed"
import { CircleTooltip } from "@/shared/ui/CircleTooltip/CircleTooltip"

import Dmitrii from '@/shared/assets/Dmitrii.webp'
import Artem from '@/shared/assets/Artem.webp'
import Aleksandr from '@/shared/assets/Aleksandr.png'
import Evgeniy from '@/shared/assets/Evgeniy.webp'
import Vadim from '@/shared/assets/Rim.webp'

import cls from "./team_block.module.scss"

export const TeamBlock = () => {
    const data = [
        { name: "Dmitrii Vysochin", role: "Ceo Founder", img: Dmitrii },
        { name: "Artem Gorodetskii", role: "CPO", img: Artem },
        { name: "Aleksandr Detkov", role: "CTO", img: Aleksandr },
        { name: "Evgeniy Volokhov", role: "Lead Designer", img: Evgeniy },
        { name: "Rim Khairullin", role: "Frontend Developer", img: Vadim },
    ]

    const blogPosts = [
        {
            title: "Innovatech Solutions (IT Solutions & Consulting):",
            description:
                "Axgrid's SaaS web products, especially their CRM and custom version control system, have significantly streamlined our operations. Highly recommend!",
        },
        {
            title: "Globetrotter Games (Mobile Game Developer):",
            description:
                "Axgrid's expertise in casual mobile game design helped us create \"Cosmic Quest,\" a hit with our players worldwide. We're thrilled with the results!",
        },
        {
            title: "AdVenture Media (Digital Advertising Agency)",
            description:
                "Axgrid's advertising screen management solution, with its intuitive admin panel, powerful software, and mobile app, has completely transformed how we manage our advertising network.",
        },
        {
            title: "Zenith Analytics (Data Analytics & Business Intelligence):",
            description:
                "Axgrid's IT Business Products, including their log visualization, metrics dashboards, and translation service, are essential tools for managing our data and providing insights to our international clients.",
        },
        {
            title: " The Green Initiative (Environmental Consulting Firm):",
            description:
                "Axgrid's task tracker and time tracking tools have been a game-changer for our environmental projects, improving our team's productivity and organization.",
        },
        {
            title: "Stellar Innovations (Software Development Company):",
            description: "Axgrid's packaging and design expertise helped us create a sleek and engaging online gambling platform. We're very impressed with their work.",
        },
    ]

    const [windowWidth, setWindowWidth] = useState(window.outerWidth)

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth)
        })

        return () => {
            window.removeEventListener("resize", () => {
                setWindowWidth(window.innerWidth)
            })
        }
    }, [windowWidth])

    return (
        <div
            id='team_block'
            className={cls.container}>
            <div className={cls.typedText}>
                <span>
                    <ReactTyped
                        startWhenVisible
                        backDelay={3000}
                        fadeOut={true}
                        strings={["We are"]}
                        typeSpeed={200}
                        showCursor={false}
                    />
                </span>
                <br />
                <span>
                    <ReactTyped
                        startDelay={1200}
                        startWhenVisible
                        backDelay={3000}
                        fadeOut={true}
                        strings={["a young"]}
                        typeSpeed={200}
                        showCursor={false}
                    />
                </span>
                <br />
                <span>
                    <ReactTyped
                        startDelay={2600}
                        startWhenVisible
                        backDelay={3000}
                        fadeOut={true}
                        strings={["team"]}
                        typeSpeed={200}
                        showCursor={false}
                    />
                </span>
            </div>

            <div className={cls.someText}>
                <Typography
                    variant='h3'
                    className={cls.someTextInner}
                    weight='standard'>
                    We offer complex solutions for businesses in the development
                    of digital products from the initial stages of information
                    architecture and UI/UX design to coding in the latest
                    programming languages ​​for digital services of any
                    complexity.
                </Typography>
            </div>

            <div className={cls.teammates}>
                {windowWidth > 768 ? (
                    <Marquee
                        gradient={true}
                        gradientWidth={50}
                        gradientColor='#0C0D0D'
                        className={cls.teammatesMarquee}>
                        {data.map((item, index) => (
                            <TeammateCard
                                key={index}
                                name={item.name}
                                role={item.role}>
                                <img
                                    src={item.img}
                                    className={cls.teammateImg}
                                />
                            </TeammateCard>
                        ))}
                    </Marquee>
                ) : (
                    data.map((item, index) => (
                        <TeammateCard
                            key={index}
                            name={item.name}
                            role={item.role}>
                            <img
                                src={item.img}
                                className={cls.teammateImg}
                            />
                        </TeammateCard>
                    ))
                )}
            </div>
            <Marquee
                pauseOnHover={true}
                className={cls.blogPostMarquee}>
                <div className={cls.blogPost}>
                    {blogPosts.map((post, index) => {
                        return (
                            <div className={cls["blogPostContainer" + index]}>
                                <BlogPost title={post.title} description={post.description} />
                            </div>
                        )
                    })}
                </div>
            </Marquee>
        </div>
    )
}
