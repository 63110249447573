import React, { useRef, useState } from "react"
import cls from "./business.module.scss"
import { Icon } from "@/shared/ui/Icon"
import { classNames } from "@/shared/lib/classNames"
import useClickOutside from "@/shared/lib/hooks/useClickOutside"

export const BusinessInfo = () => {
    const [open, setOpen] = useState(false)
    const infoRef = useRef()

    useClickOutside(infoRef, () => {
        setOpen(false)
    })

    return (
        <div
            ref={infoRef}
            className={cls.container}>
            <div
                className={classNames(cls.menu, [], {
                    [cls.close]: !open,
                })}>
                <h2>Business Information</h2>
                <table>
                    <tbody className={cls.tableBody}>
                        <tr>
                            <th scope='row'>ID</th>
                            <td>53870093</td>
                        </tr>
                        <tr>
                            <th scope='row'>TIN</th>
                            <td>2121530565</td>
                        </tr>
                        <tr>
                            <th scope='row'>VAT NUMBER</th>
                            <td>
                                SK2121530565, according to §7a, registration
                                from 11.1.2022
                            </td>
                        </tr>
                        <tr className={cls.large}>
                            <th scope='col'>Head office</th>
                            <td>
                                Softner Solutions, s.r.o. Lermontovova 3 811 05
                                Bratislava - Old Town
                            </td>
                        </tr>
                        <tr className={cls.large}>
                            <th scope='col'>Date of creation</th>
                            <td>Saturday, 26 June 2021</td>
                        </tr>
                        <tr className={cls.large}>
                            <th scope='col'>SK NACE</th>
                            <td>
                                according to the financial statements: 62010
                                Computer Programming according to the
                                Statistical Office of the Slovak Republic: 62090
                                Other information services information
                                technology and computers
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button
                onClick={() => setOpen(!open)}
                className={classNames(cls.BusinessInfo, [], {
                    [cls.open]: open,
                })}>
                Business Info
                <Icon
                    type='select-arrow'
                    className={classNames(cls.arrow, [], {
                        [cls.open]: open,
                    })}
                    stroke='black'
                />
            </button>
        </div>
    )
}
