import React from "react"
import { Typography } from "@/shared/ui/Typography"
import { DropdownList } from "@/features/DropdownList/DropdownList"
import { UniqMarqueeText } from "@/shared/uniq-text/UniqMarqueeText"
import cls from "./works_block.module.scss"

export const WorksBlock = () => {
    return (
        <div
            id='works_block'
            className={cls.worksBlock}>
            <div>
                <div className={cls.titleContainer}>
                    <Typography
                        className={cls.title}
                        variant='h2'
                        weight='medium'>
                        Works
                    </Typography>
                    <UniqMarqueeText className={cls.uniqText} />
                </div>
                <div className={cls.someText}>
                    <Typography
                        variant='h3'
                        style={{
                            width: "635px",
                            margin: "200px 120px",
                            zIndex: 9,
                        }}
                        weight='standard'>
                        We build technology that empowers your vision.
                    </Typography>
                </div>

                <DropdownList />
            </div>
        </div>
    )
}
